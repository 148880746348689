<template >
  <div class="content">
    <div class="headArea">
      <img :src="detail.headImg" class="headImg" />
    </div>
    <div class="titleLine">
      <span class="titleArea">{{detail.title}}</span>
      <div class="item-icon-yan">
        <img src="../imgs/yan2.png" />
        <span>{{detail.yanNum}}</span>
      </div>
    </div>
    <div class="addressLine">
      <img src="../imgs/location.png" style="width:0.267rem;height:0.32rem;margin-top:0.08rem;" />
      <div class="addressArea">{{detail.address}}</div>
      <img
        src="../imgs/arrow.png"
        style="width:0.2rem;height:0.32rem;margin-top:0.08rem;margin-left:1.92rem;"
        @click="gotoMap"
      />
    </div>
    <div class="audioArea" loop v-if="detail.audioSrc">
      <audio class="width:100%;height:100%" id="music" controls>
        <source :src="detail.audioSrc" type="audio/ogg" />
        <source :src="detail.audioSrc" type="audio/mpeg" />您的浏览器不支持 audio 元素。
      </audio>
    </div>
    <!-- <div class="titleTab">
      <div class="titleLine">
        <span style="margin-left:0.4rem" id="titleText">{{detail.title}}</span>
      </div>
      <div class="contentLine">
        <div class="contentLoad" id="addressText">
          <div class="contentLoadLeft">
            <img src="../imgs/dingwei.png" class="dingwei" />
          </div>
          <div class="contentLoadCenter">{{detail.address}}</div>
          <div class="contentLoadRight" @click="gotoMap">
            <img src="../imgs/more2.png" class="dingwei" />
          </div>
        </div>
        <div class="phoneText">
          <div class="contentLoadLeft">
            <img src="../imgs/phone.png" class="dingwei" />
          </div>
          <div class="contentLoadCenter">{{detail.phone}}</div>
        </div>
        <div class="item-icon">
          <div class="item-icon-yan">
            <img src="../imgs/yan.png" style="width: 0.48rem;height:0.48rem" />
            <span id="numText">{{detail.yanNum}}</span>
          </div>
          <div class="item-icon-yan">
            <img src="../imgs/zan.png" style="width: 0.32rem;height:0.213rem" />
            <span id="zanText">{{detail.zan}}</span>
          </div>
          <div style="width: 0.4rem; height:100%"></div>
        </div>
      </div>
    </div>-->
    <div>
      <div class="detailHeadArea">
        <div class="headLine">
          <img src="../imgs/orangeColumn.png" style="width:0.0533rem;height:0.2666rem;" />
          <span class="titleFont" id="introduceText">景点介绍</span>
        </div>
      </div>

      <div class="rich-text" v-html="detail.rich_text"></div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
export default {
  name: 'TheGreatView',

  data() {
    return {
      detail: {},
      contentId: 0
    };
  },
  watch: {},
  created() {
    this.getRecords();
  },
  mounted() {
    const timer = setTimeout(function() {
      var audio = document.getElementById('music');
      if (audio !== null) {
        console.log(audio);
        audio.load();
        audio.play();
      }
    }, 1000);
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const this_ = this;
      const contentId = this_.$route.query.contentId;
      this_.contentId = contentId;
      this_.$api
        .getContentDetail({
          contentId: contentId,
          topicSymbol: 'h5-attractions-detail'
        })
        .then(res => {
          if (res.data.code === 10000) {
            console.log(res);
            const obj = {};
            const item = res.data.data;
            console.log(item);
            if (Object.keys(item).length > 0) {
              obj.headImg = item.content.first_figure;
              obj.title = item.title;
              obj.address = item.content.location_text;
              obj.yanNum = item.readCount;
              obj.zan = item.praiseCount;
              obj.contentTitle = '景点介绍';
              obj.phone = item.content.phone;
              obj.audioSrc = item.content.introduction_audio;
              // obj.audioSrc ='http://oss.tslbrsr.com/static/26dba8dd-19a4-4066-8298-69c1301fbada.mp3';
              obj.rich_text = item.content.introduction_text
                ? item.content.introduction_text.replace(
                    '<img ',
                    "<img style='width:100%;height:auto' "
                  )
                : '';
              obj.position =
                item.content.location.constructor == Array
                  ? item.content.location
                  : item.content.location.split(',').map(item => {
                      return parseFloat(item);
                    });
              // obj.position = item.content.position;
            }
            console.log(obj);
            this_.detail = obj;
          } else {
            Notify({ type: 'warning', message: res.data.msg });
          }
        });
    },
    gotoMap() {
      const this_ = this;
      const contentId = this_.contentId;
      this.$router.push({
        name: 'meiCityMap',
        query: {
          contentId: contentId,
          topicSymbol: 'h5-attractions-detail'
        }
      });
    }
  }
};
</script>
<style scoped>
.content {
  width: 100%;
  height: 100%;
  background-color: rgb(242, 239, 222);
  position: relative;
}
.headArea {
  width: 100%;
  height: 4.8rem;
}
.headImg {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.titleTab {
  position: absolute;
  top: 4.48rem;
  left: 0.4rem;
  width: 9.2rem;
  height: 4.027rem;
  background: rgba(255, 255, 255, 1);
  border-radius: 0.107rem;
}

.bannerBak {
  width: 100%;
  height: 2.587rem;
  background: rgba(247, 247, 247, 1);
}

.titleLine {
  height: 0.427rem;
  margin: 0.267rem 0;
  padding: 0 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.titleArea {
  color: #222;
  font-size: 0.427rem;
  font-weight: 700;
}
.item-icon {
  margin-top: 0.267rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-icon-yan {
  height: 0.48rem;
  float: left;
  color: #555;
  font-size: 0.2933rem;
}

.item-icon-yan img {
  width: 0.4rem;
  height: 0.267rem;
  float: left;
}

.item-icon-yan span {
  font-size: 0.293rem;
  color: #222;
  text-indent: 0.053rem;
  float: left;
}
.addressLine {
  height: 0.8rem;
  margin: 0 0.4rem;
  display: flex;
  justify-content: flex-start;
}

.addressArea {
  height: 100%;
  width: 6.707rem;
  color: #555;
  font-size: 0.32rem;
  margin-left: 0.107rem;
}
.contentLine {
  height: 2.613rem;
  width: 100%;
}

.contentLoad {
  margin: 0.4rem 0.4rem 0 0.4rem;
  height: 1.067rem;
  font-size: 0.373rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(136, 136, 136, 1);
}
.contentLoadLeft {
  width: 7%;
  height: 100%;
  float: left;
}
.contentLoadCenter {
  width: 86%;
  height: 100%;
  float: left;
}
.contentLoadRight {
  width: 7%;
  height: 100%;
  float: left;
}
.item-icon {
  height: 0.533rem;
  /* margin-top: 0.267rem; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.item-icon-yan {
  font-size: 0.293rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  margin-left: 0.15rem;
  display: flex;
  align-items: center;
}

.item-icon-zan {
  font-size: 0.293rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
}

.detailHeadArea {
  height: 1.387rem;
  border-bottom: #eeeeee 0.013rem solid;
  display: flex;
  align-items: center;
}

.headLine {
  height: 0.6rem;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
}

.blueArea {
  width: 0.08rem;
  height: 0.4rem;
  background-color: #00a5ff;
  opacity: 1;
}

.titleFont {
  width: 6.667rem;
  height: 0.6rem;
  margin-left: 0.16rem;
  font-size: 0.427rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(34, 34, 34, 1);
}

.rich-text {
  margin: 0.427rem 0.4rem;
  font-size: 0.347rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
}

.rich-text img {
  width: 100%;
}
.dingwei {
  width: 0.48rem;
  height: 0.48rem;
}
.phoneText {
  margin: 0rem 0.4rem 0 0.4rem;
  height: 0.5rem;
  font-size: 0.373rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(136, 136, 136, 1);
}
.audioArea {
  height: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>



